/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
import { css, t } from '@superset-ui/core';
import ValidatedInput from 'src/components/Form/LabeledErrorBoundInput';
import FormLabel from 'src/components/Form/FormLabel';
import Icons from 'src/components/Icons';
import { StyledFooterButton, StyledCatalogTable } from '../styles';
export const TableCatalog = ({ required, changeMethods, getValidation, validationErrors, db, }) => {
    const tableCatalog = db?.catalog || [];
    const catalogError = validationErrors || {};
    return (React.createElement(StyledCatalogTable, null,
        React.createElement("h4", { className: "gsheet-title" }, t('Connect Google Sheets as tables to this database')),
        React.createElement("div", null,
            tableCatalog?.map((sheet, idx) => (React.createElement(React.Fragment, null,
                React.createElement(FormLabel, { className: "catalog-label", required: true }, t('Google Sheet Name and URL')),
                React.createElement("div", { className: "catalog-name" },
                    React.createElement(ValidatedInput, { className: "catalog-name-input", required: required, validationMethods: { onBlur: getValidation }, errorMessage: catalogError[idx]?.name, placeholder: t('Enter a name for this sheet'), onChange: (e) => {
                            changeMethods.onParametersChange({
                                target: {
                                    type: `catalog-${idx}`,
                                    name: 'name',
                                    value: e.target.value,
                                },
                            });
                        }, value: sheet.name }),
                    tableCatalog?.length > 1 && (React.createElement(Icons.CloseOutlined, { css: (theme) => css `
                    align-self: center;
                    background: ${theme.colors.grayscale.light4};
                    margin: 5px 5px 8px 5px;

                    &.anticon > * {
                      line-height: 0;
                    }
                  `, iconSize: "m", onClick: () => changeMethods.onRemoveTableCatalog(idx) }))),
                React.createElement(ValidatedInput, { className: "catalog-name-url", required: required, validationMethods: { onBlur: getValidation }, errorMessage: catalogError[idx]?.url, placeholder: t('Paste the shareable Google Sheet URL here'), onChange: (e) => changeMethods.onParametersChange({
                        target: {
                            type: `catalog-${idx}`,
                            name: 'value',
                            value: e.target.value,
                        },
                    }), value: sheet.value })))),
            React.createElement(StyledFooterButton, { className: "catalog-add-btn", onClick: () => {
                    changeMethods.onAddTableCatalog();
                } },
                "+ ",
                t('Add sheet')))));
};

/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { useState } from 'react';
import { t } from '@superset-ui/core';
import { AntdButton, AntdSelect } from 'src/components';
import InfoTooltip from 'src/components/InfoTooltip';
import FormLabel from 'src/components/Form/FormLabel';
import Icons from 'src/components/Icons';
import { infoTooltip, labelMarginBottom, CredentialInfoForm } from '../styles';
var CredentialInfoOptions;
(function (CredentialInfoOptions) {
    CredentialInfoOptions[CredentialInfoOptions["JsonUpload"] = 0] = "JsonUpload";
    CredentialInfoOptions[CredentialInfoOptions["CopyPaste"] = 1] = "CopyPaste";
})(CredentialInfoOptions || (CredentialInfoOptions = {}));
// These are the columns that are going to be added to encrypted extra, they differ in name based
// on the engine, however we want to use the same component for each of them. Make sure to add the
// the engine specific name here.
export const encryptedCredentialsMap = {
    gsheets: 'service_account_info',
    bigquery: 'credentials_info',
};
const castStringToBoolean = (optionValue) => optionValue === 'true';
export const EncryptedField = ({ changeMethods, isEditMode, db, editNewDb, }) => {
    const [uploadOption, setUploadOption] = useState(CredentialInfoOptions.JsonUpload.valueOf());
    const [fileToUpload, setFileToUpload] = useState(null);
    const [isPublic, setIsPublic] = useState(true);
    const showCredentialsInfo = db?.engine === 'gsheets' ? !isEditMode && !isPublic : !isEditMode;
    const isEncrypted = isEditMode && db?.masked_encrypted_extra !== '{}';
    const encryptedField = db?.engine && encryptedCredentialsMap[db.engine];
    const encryptedValue = typeof db?.parameters?.[encryptedField] === 'object'
        ? JSON.stringify(db?.parameters?.[encryptedField])
        : db?.parameters?.[encryptedField];
    return (React.createElement(CredentialInfoForm, null,
        db?.engine === 'gsheets' && (React.createElement("div", { className: "catalog-type-select" },
            React.createElement(FormLabel, { css: (theme) => labelMarginBottom(theme), required: true }, t('Type of Google Sheets allowed')),
            React.createElement(AntdSelect, { style: { width: '100%' }, defaultValue: isEncrypted ? 'false' : 'true', onChange: (value) => setIsPublic(castStringToBoolean(value)) },
                React.createElement(AntdSelect.Option, { value: "true", key: 1 }, t('Publicly shared sheets only')),
                React.createElement(AntdSelect.Option, { value: "false", key: 2 }, t('Public and privately shared sheets'))))),
        showCredentialsInfo && (React.createElement(React.Fragment, null,
            React.createElement(FormLabel, { required: true }, t('How do you want to enter service account credentials?')),
            React.createElement(AntdSelect, { defaultValue: uploadOption, style: { width: '100%' }, onChange: option => setUploadOption(option) },
                React.createElement(AntdSelect.Option, { value: CredentialInfoOptions.JsonUpload }, t('Upload JSON file')),
                React.createElement(AntdSelect.Option, { value: CredentialInfoOptions.CopyPaste }, t('Copy and Paste JSON credentials'))))),
        uploadOption === CredentialInfoOptions.CopyPaste ||
            isEditMode ||
            editNewDb ? (React.createElement("div", { className: "input-container" },
            React.createElement(FormLabel, { required: true }, t('Service Account')),
            React.createElement("textarea", { className: "input-form", name: encryptedField, value: encryptedValue, onChange: changeMethods.onParametersChange, placeholder: t('Paste content of service credentials JSON file here') }),
            React.createElement("span", { className: "label-paste" }, t('Copy and paste the entire service account .json file here')))) : (showCredentialsInfo && (React.createElement("div", { className: "input-container", css: (theme) => infoTooltip(theme) },
            React.createElement("div", { css: { display: 'flex', alignItems: 'center' } },
                React.createElement(FormLabel, { required: true }, t('Upload Credentials')),
                React.createElement(InfoTooltip, { tooltip: t('Use the JSON file you automatically downloaded when creating your service account.'), viewBox: "0 0 24 24" })),
            !fileToUpload && (React.createElement(AntdButton, { className: "input-upload-btn", onClick: () => document?.getElementById('selectedFile')?.click() }, t('Choose File'))),
            fileToUpload && (React.createElement("div", { className: "input-upload-current" },
                fileToUpload,
                React.createElement(Icons.DeleteFilled, { iconSize: "m", onClick: () => {
                        setFileToUpload(null);
                        changeMethods.onParametersChange({
                            target: {
                                name: encryptedField,
                                value: '',
                            },
                        });
                    } }))),
            React.createElement("input", { id: "selectedFile", accept: ".json", className: "input-upload", type: "file", onChange: async (event) => {
                    let file;
                    if (event.target.files) {
                        file = event.target.files[0];
                    }
                    setFileToUpload(file?.name);
                    changeMethods.onParametersChange({
                        target: {
                            type: null,
                            name: encryptedField,
                            value: await file?.text(),
                            checked: false,
                        },
                    });
                    document.getElementById('selectedFile').value = null;
                } }))))));
};
